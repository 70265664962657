import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MobileCoreRoutes } from './routes/mobile-core.routes';

const routes: Routes = [
  {
    path: MobileCoreRoutes.main,
    loadChildren: () =>
      import('./templates/core-template/mobile-core-template.module').then(
        m => m.MobileCoreTemplateModule
      ),
  },
  {
    path: MobileCoreRoutes.error + '/:dictionaryFails',
    loadChildren: () =>
      import(
        '../../../core/views/sd-general-error-a11y/sd-general-error-a11y.module'
      ).then(m => m.SdGeneralErrorA11yModule),
  },
  {
    path: MobileCoreRoutes.error,
    loadChildren: () =>
      import(
        '../../../core/views/sd-general-error-a11y/sd-general-error-a11y.module'
      ).then(m => m.SdGeneralErrorA11yModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MobileCoreRoutingModule {}
